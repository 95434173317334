import Axios from "../../request";
import AxiosQx from "../../request_qx";
import {
  ResponseDetailType,
  ResponseListType,
  ResponseType,
} from "../../interface";
import {
  RoleCreateProps,
  RoleDeleteProps,
  RolePageProps,
  addProjectEntity,
  projectEntity,
  RolePermissionPropsObj,
  UserListProps,
  RolePermissionProps,
  UserUpdateProps,
  applicationEntity,
  versionMenuEntity,
  UserCreateProps,
  OrganizationCreateProps,
  BindProps,
  appListType,
  TreeType,
  roleTreeType,
  componentsListType,
  authListType,
  authDeleteType,
  authExportType,
  deactivatedType,
  authAddType,
  authUpdateType,
  getAddServiceInfoListType,
  setAddServiceInfoType,
  getAddServiceConfigType,
  updateAddServiceConfigType,
  aiListType,
  updateAiType,
} from "./interface";
import { paginationEntity } from "../../../assets/common/interface/page";

export class SuperManageAPI {
  // eslint-disable-next-line no-plusplus
  // static brandList(arg0: {
  //   name?: string | undefined;
  //   pageSize: number;
  //   page: number;
  // }):
  //   | ResponseListType<
  //     import("../../../pages/deviceCenter/productBrand/interface").TableType
  //   >
  //   | PromiseLike<
  //     ResponseListType<
  //       import("../../../pages/deviceCenter/productBrand/interface").TableType
  //     >
  //   > {
  //   throw new Error("Method not implemented.");
  // }
  // eslint-disable-next-line no-plusplus
  // 项目列表
  static getProjectList(
    params: Pick<paginationEntity, "currentPage" | "pageSize"> &
      Partial<projectEntity>
  ) {
    return Axios.get("/crm/project/all", { params });
  }

  // 项目创建
  static addProject(
    data: Pick<
      projectEntity,
      | "appId"
      | "contacts"
      | "email"
      | "tenantId"
      | "name"
      | "phone"
      | "remark"
      | "type"
      | "versions"
    > &
      addProjectEntity
  ) {
    return Axios.post("/crm/project", data);
  }

  // 项目编辑
  static editProject(
    data: Pick<
      projectEntity,
      | "appId"
      | "contacts"
      | "email"
      | "id"
      | "name"
      | "phone"
      | "remark"
      | "type"
      | "versions"
      | "tenantId"
    > &
      addProjectEntity
  ) {
    return Axios.put("/crm/project", data);
  }

  // /crm/account/resetbind
  // 一键与管理员同配置
  static resetbind = (userID: number): Promise<ResponseType<never>> =>
    Axios.post(`/crm/account/resetbind?userId=${userID}`);

  // 项目删除
  static deleteProject(id: number) {
    return Axios.delete("/crm/project", { params: { id } });
  }

  // 项目详情
  static getProjectDetail(id: number) {
    return Axios.get(`/crm/project/${id}`);
  }

  // 根据应用类型应用列表（新增或编辑）
  static getApplicationList(type: number) {
    return Axios.get("/crm/project/allApp", { params: { type } });
  }

  // 根据应用类型应用列表（列表查询）
  static getApplicationDataByType(type: number) {
    return Axios.get("/crm/project/allAppList", { params: { type } });
  }

  // 应用列表
  static getApplicationData(
    params: Pick<paginationEntity, "pageSize" | "currentPage"> & {
      name?: string;
    }
  ): Promise<ResponseListType<applicationEntity>> {
    return Axios.get("/crm/application", { params });
  }

  // 创建应用
  static addApplication = (
    data: Pick<
      applicationEntity,
      "icon" | "manageUrl" | "url" | "type" | "remake"
    >
  ): Promise<ResponseListType<any>> => {
    return Axios.post("/crm/application", data);
  };

  // 编辑应用
  static editApplication = (
    data: Pick<
      applicationEntity,
      "icon" | "manageUrl" | "url" | "type" | "remake" | "id"
    >
  ): Promise<ResponseListType<any>> => {
    return Axios.put("/crm/application", data);
  };

  // 删除应用
  static deleteApplication = (id: number): Promise<ResponseType<any>> => {
    return Axios.delete(`/crm/application/${id}`);
  };

  // 所有应用列表
  static getApplicationAll = (
    name?: string
  ): Promise<ResponseType<applicationEntity[]>> => {
    return Axios.get("/crm/application/all", { params: { name } });
  };

  // 组件管理-版本管理-应用基础菜单
  static getBaseAppTree = (
    appId: number,
    sysType: number
  ): Promise<ResponseType<versionMenuEntity[]>> => {
    return Axios.get("/crm/menu/baseAppTree", { params: { appId, sysType } });
  };

  // 组件管理-版本管理-应用版本菜单
  static getAppMenuByversion = (params: {
    appId: number;
    versions: string;
    sysType: number;
  }): Promise<ResponseType<versionMenuEntity[]>> => {
    return Axios.get("/crm/menu/getAppMenuByversion", { params });
  };

  // 组件管理-版本管理-配置版本菜单
  static updateAppMenuByversion = (
    menus: { id: number }[],
    appId: number,
    versions: string,
    sysType: number
  ): Promise<ResponseType<any>> => {
    return Axios.put("/crm/menu/updateAppMenuByversion", {
      menus,
      appId,
      versions,
      sysType,
    });
  };

  // 组件管理-版本管理-更新基础菜单
  static updateAppBasicMenu = (
    menus: { id: number }[],
    appId: number,
    sysType: number
  ): Promise<ResponseType<any>> => {
    return Axios.put("/crm/menu/updateAppBasicMenu", {
      menus,
      appId,
      sysType,
    });
  };

  // 机构列表
  static getOrganizationAll() {
    return Axios.get("/crm/organization/tree");
  }

  // 机构渠道枚举
  static orgChannel() {
    return Axios.get("/crm/organization/channel");
  }

  // 查询角色列表(分页)
  static rolePage = (data: RolePageProps): Promise<ResponseListType<never>> =>
    Axios.get("/crm/role/page", { params: data });

  // 可见项目下拉列表
  static rolePullDownProject = (): Promise<ResponseType<never>> =>
    Axios.put("/crm/role/pullDownProject");

  // 创建角色
  static roleCreate = (data: RoleCreateProps): Promise<ResponseType<never>> =>
    Axios.post("/crm/role", data);

  // 编辑角色
  static roleUpdate = (
    data: RoleCreateProps & { id: number }
  ): Promise<ResponseType<never>> => Axios.post("/crm/role/updateRole", data);

  // 删除角色
  static roleDelete = (data: RoleDeleteProps): Promise<ResponseType<never>> =>
    Axios.delete("/crm/role", { params: data });

  // 角色配置-获取应用列表
  static allApplication = (data: {
    name?: string;
  }): Promise<ResponseType<{ id: number; name: string }>> =>
    Axios.get("/crm/application/all", { params: data });

  // 角色配置-权限列表
  static roleTree = (data: {
    versions: string;
    appId: number;
    roleId: number;
    sysType?: number;
  }): Promise<ResponseType<any>> =>
    Axios.get("/crm/role/tree", { params: data });

  // 用户管理-查询账号列表
  static userList = (data: UserListProps): Promise<ResponseListType<never>> =>
    Axios.get("/crm/user/list", { params: data });

  // 用户管理-查询全部机构
  static organizationAll = (data: {
    name?: string;
  }): Promise<ResponseType<never>> =>
    Axios.get("/crm/organization/tree", { params: data });

  // 用户管理-更新账号信息
  static userUpdate = (data: UserUpdateProps): Promise<ResponseType<never>> =>
    Axios.put("/crm/user", data);

  // 用户管理-创建账号
  static userCreate = (data: UserCreateProps): Promise<ResponseType<never>> =>
    Axios.post("/crm/user", data);

  // 用户管理-删除账号
  static userDelete = ({ id }: { id: number }): Promise<ResponseType<never>> =>
    Axios.delete(`/crm/user/${id}`);

  // 用户管理-密码重置
  static userReset = (data: {
    id: number;
    password: string;
  }): Promise<ResponseType<never>> =>
    Axios.get("/crm/user/reset", { params: data });

  // 机构管理-行政地区下拉列表
  static organizationArea = (): Promise<ResponseType<never>> =>
    Axios.get("/crm/organization/area");

  // 机构管理-创建机构
  static organizationCreate = (
    data: OrganizationCreateProps
  ): Promise<ResponseType<never>> => Axios.post("/crm/organization", data);

  // 机构管理-更新机构信息
  static organizationUpdate = (
    data: OrganizationCreateProps & { id?: number }
  ): Promise<ResponseType<never>> => Axios.put("/crm/organization", data);

  // 机构管理-删除机构
  static organizationDelete = ({
    id,
  }: {
    id: number;
  }): Promise<ResponseType<never>> => Axios.delete(`/crm/organization/${id}`);

  // 系统管理-查询角色列表(分页)
  static roleProjectPage = (
    data: RolePageProps
  ): Promise<ResponseListType<never>> =>
    Axios.get("/crm/roleProject/page", { params: data });

  // 系统管理-创建角色
  static createRole = (data: RoleCreateProps): Promise<ResponseType<never>> =>
    Axios.post("/crm/roleProject", data);

  // 系统管理-编辑角色
  static updateRole = (
    data: RoleCreateProps & { id: number }
  ): Promise<ResponseType<never>> =>
    Axios.post("/crm/roleProject/updateRole", data);

  // 系统管理-删除角色
  static deleteRole = (data: RoleDeleteProps): Promise<ResponseType<never>> =>
    Axios.delete("/crm/roleProject", { params: data });

  // 系统管理-角色管理-权限管理-左侧菜单
  static roleProject = (
    data: roleTreeType
  ): Promise<ResponseType<appListType[]>> =>
    Axios.get(`/crm/roleProject/getRoleProject`, { params: data });

  // 系统管理-角色管理-权限管理-中间菜单
  static roleProjectTree = (data: TreeType): Promise<ResponseType<never>> =>
    Axios.get("/crm/roleProject/tree", { params: data });

  // 系统管理-角色管理-权限管理-中间菜单(主平台权限)
  static rolePlatformTree = (data: TreeType): Promise<ResponseType<never>> =>
    Axios.get("/crm/roleProject/platformTree", { params: data });

  // 系统管理-角色配置-角色权限-修改权限更新,配置权限和数据权限
  static updateRoleProjectPermission = (
    data: RolePermissionProps
  ): Promise<ResponseType<never>> =>
    AxiosQx.put("/crm/roleProject/updateRoleProjectPermission", data);

  // 角色配置-角色权限-修改权限更新,配置权限和数据权限
  static rolePermission = (
    data: RolePermissionProps
  ): Promise<ResponseType<never>> =>
    AxiosQx.put("/crm/role/updateRolePermission", data);

  // 系统管理-角色配置-v3角色权限-主平台修改权限更新,配置权限和数据权限
  static updateRolePlatformPermission = (
    data: RolePermissionPropsObj
  ): Promise<ResponseType<never>> =>
    Axios.put("/crm/roleProject/updateRolePlatformPermission", data);

  // 用户管理-查询账号列表
  static listUser = (data: UserListProps): Promise<ResponseListType<never>> =>
    Axios.get("/crm/userProject/list", { params: data });

  // 用户管理-更新账号信息
  static updateUser = (data: UserUpdateProps): Promise<ResponseType<never>> =>
    Axios.put("/crm/userProject", data);

  // 用户管理-创建账号
  static createUser = (data: UserCreateProps): Promise<ResponseType<never>> =>
    Axios.post("/crm/userProject", data);

  // 用户管理-账号详情
  static detailUser = ({ id }: { id: number }): Promise<ResponseDetailType> =>
    Axios.get(`/crm/userProject/${id}`);

  // 用户管理-删除账号
  static deleteUser = ({ id }: { id: number }): Promise<ResponseType<never>> =>
    Axios.delete(`/crm/userProject/${id}`);

  // 用户管理-关联登录-绑定数据回显列表
  static getBindList = (data: FormData): Promise<ResponseType<never>> =>
    Axios.post(`/crm/account/bindList`, data);

  // 用户管理-关联登录-绑定平台
  static BindTerrace = (data: BindProps): Promise<ResponseType<never>> =>
    Axios.post(`/crm/account/associatedLogin`, data);

  // 用户管理-关联登录-平台解绑
  static unBindTerrace = (data: FormData): Promise<ResponseType<never>> =>
    Axios.post(`/crm/account/unbind`, data);

  //   组件管理-列表
  static getComponentsList: componentsListType = (params) =>
    Axios.get("/crm/externalModule/getModuleList", { params });

  //   授权管理-列表
  static getAuthList: authListType = (params) =>
    Axios.get("/crm/externalModule/getModuleUserAuthList", { params });

  // 授权管理-禁用启用
  static deactivatedAuth: deactivatedType = (data) =>
    Axios.post("/crm/externalModule/updateModuleUserAuthType", data);

  //   授权管理-删除
  static deleteAuth: authDeleteType = (params) =>
    Axios.delete("/crm/externalModule/delModuleUserAuthInfo", { params });

  //   授权管理-导出
  static authExport: authExportType = (params) =>
    Axios.get("/crm/externalModule/getReportModule", {
      params,
      responseType: "blob",
    });

  //   授权管理-新增
  static authAdd: authAddType = (data) =>
    Axios.post("/crm/externalModule/addModuleUserAuth", data);

  //   授权管理-编辑
  static authUpdate: authUpdateType = (data) =>
    Axios.post("/crm/externalModule/updateModuleUserAuthInfo", data);

  //   流量和存储服务-列表
  static getAddServiceInfoList: getAddServiceInfoListType = (params) =>
    Axios.get("/crm/addService/getAddServiceInfotList", { params });

  //   流量和存储服务-设置
  static setAddServiceInfo: setAddServiceInfoType = (data) =>
    Axios.post("/crm/addService/setAddServiceConfig", data);

  //  流量和存储服务-设置获取
  static getAddServiceConfig: getAddServiceConfigType = (params) =>
    Axios.get("/crm/addService/getAddServiceConfig", { params });

  //  流量和存储服务-设置更新
  static updateAddServiceConfig: updateAddServiceConfigType = (data) =>
    Axios.put("/crm/addService/updateAddServiceConfig", data);

  //   增值-ai-算法列表
  static getAiList: aiListType = (params) =>
    Axios.get("/crm/project-ai-alg-setting", { params });

  //   增值-ai-算法更新
  static updateAi: updateAiType = (data) =>
    Axios.put("/crm/project-ai-alg-setting", data);
}
